<template>
  <div class="Menu wrapper" :class="{ ativo: !menu_mobile }">
    <div class="Menu logo-close">
      <div class="Menu-logo mb-4">
        <router-link to="/home" class="after-element">
          <img
            v-if="currentLogo"
            :src="currentLogo"
            alt="logo"
            class="ml-logo2"
          />
          <div v-else class="ml-logo mb-4 mt-4"></div>
        </router-link>

        <div class="btn-menu" @click="menu_mobile = !menu_mobile">
          {{ menu_mobile ? $t("locales.menu") : $t("locales.close") }}
          <img
            src="@/assets/icons/menu.svg"
            v-show="menu_mobile"
            data-anima="mobile"
          />
          <img
            src="@/assets/icons/close.svg"
            v-show="!menu_mobile"
            data-anima="mobile"
          />
        </div>
      </div>

      <Menu v-if="!menu_mobile" data-anima="top" />
    </div>
  </div>
</template>
<script>
// import Menu from "./Menu";
import Menu from "./Menu";
import Cookies from 'js-cookie';

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu_mobile: true,
      salesActive: false,
    };
  },
  computed: {
    currentLogo() {
      if (this.$store.getters.metasSite) {
        return this.$store.getters.metasSite.logo;
      } else return null;
    },
  },
  mounted(){
    this.insertTheme();
  },
  methods: {
    insertTheme() {
      const d = document;
      var theme = Cookies.get("themeClub");
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute("data-theme", "light");
      } else {
        d.documentElement.setAttribute("data-theme", theme);
      }
      this.$root.$emit("loadTheme");
      this.$root.$emit('loadsettheme');
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function (err) {});
    },
    maximizeSidebar() {
      this.$store.commit("changeSidebarState");
    },
  },
};
</script>

<style lang="scss" scoped>
.ml-logo2 {
  width: 30px !important;
  height: auto;
}
.Menu {
  height: 70px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  padding: 5px 15px;
  border-right: none;
  border-left: none;
  transition: 0.3s;
  background: var(--backgroundcolor);

  .Menu-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;
    img {
      width: 90px;
      height: auto;
    }
  }

  .Menu-collapse {
    text-align: center;
    margin-bottom: 10px;
    color: var(--maincolor);
    cursor: pointer;
    padding: 10px;
  }
}
.Menu.ativo {
  height: 100% !important;
}
.btn-menu {
  padding: 15px;
  padding-right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--fontcolor);
}
.btn-menu img {
  margin-left: 10px;
  transition: 0.3s;
  width: auto !important;
  filter: invert(50%);
}
</style>
